exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-aerofit-data-analytics-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/aerofit-data-analytics/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-aerofit-data-analytics-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-automated-trading-system-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/automated-trading-system/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-automated-trading-system-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-create-quick-backup-vim-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/create-quick-backup-vim/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-create-quick-backup-vim-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-css-variable-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/css-variable/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-css-variable-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-driver-churn-prediction-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/driver-churn-prediction/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-driver-churn-prediction-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-ecommerce-price-optimization-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/ecommerce-price-optimization/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-ecommerce-price-optimization-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-fullstack-docker-multi-container-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/fullstack-docker-multi-container/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-fullstack-docker-multi-container-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-netflix-data-analytics-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/netflix-data-analytics/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-netflix-data-analytics-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-remap-esc-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/remap-esc/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-remap-esc-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-sales-forecasting-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/sales-forecasting/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-sales-forecasting-index-md" */),
  "component---src-templates-post-tsx-content-file-path-data-blog-view-different-branch-file-index-md": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/_data/blog/view-different-branch-file/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-data-blog-view-different-branch-file-index-md" */)
}

